var w = window,
	d = document,
	viewport = 'html, body';

var throttleTime = 150, // Event firing every 'n' seconds
	debounceTime = 30;  // Event firing after 'n' seconds



/* =====================================================================
	Smooth Scroll
======================================================================== */
var speed = 380,
	$toTop = $('.totop');

$('.smsc').find('a').on('click', function() {
	var href = $(this).attr('href'),
		target = $(href == '#' || href == '' ? 'html' : href),
		position = target.offset().top;

	$(viewport).animate({scrollTop : position}, speed, 'easeInSine');
	return false;
});

/*
$(d).on('click','.pagetop',function(){
	$viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', function(e){
		if( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
			$viewport.stop().off('scroll mousedown DOMMouseScroll mousewheel keyup');
		}
	}).animate({ scrollTop: 0 },500,'linear');
	return false;
}).on('click','.smsc',function(){
	var href = $(this).attr('href').replace(/^.*#/, "#"),
		pos = $(href == "#" || href == "" ? viewport : href ).offset().top;
	$viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', function(e){
		if( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
			$viewport.stop().off('scroll mousedown DOMMouseScroll mousewheel keyup');
		}
	}).animate({ scrollTop: pos },300,'linear');
	return false;
});
*/

$(w).on('scroll', $.throttle(throttleTime,
	function() {
		if ($(this).scrollTop() > 200) {
			$toTop.addClass('active');
		} else {
			$toTop.removeClass('active');
		}
	})
);



/* =====================================================================
	Nav Active
======================================================================== */
/*
window.onload = function () {
	$('nav li a').each(function(){
		
		var $href = $(this).attr('href');
		
		if(location.href == 'https://swimmingday.com/'||
		   location.href == 'https://swimmingday.com/2020/'){
			$('nav li:first a').addClass('active');
		} else {
			if(location.href.match($href)) {
				$(this).addClass('active');
			} else {
				$(this).removeClass('active');
			}
		}
	});
};
*/



/* =====================================================================
	sp nav
======================================================================== */
$('#sp_menu').on("click", function() {
	$(this).toggleClass('active').next().stop().slideToggle();
});



/* =====================================================================
	tell link PCinvalid
======================================================================== */
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
	$('a[href^="tel:"]').on('click', function(e) {
		e.preventDefault();
	});
}



/* =====================================================================
	accordion from timetable
======================================================================== */
window.onload = function () {
	var urlHash = location.hash;
	$(urlHash).prev('.event_btn').addClass('active')
			  .next().slideDown(500, 'easeOutQuint', targetAction);
			  
	function targetAction() {
		var targetBtn = $(urlHash).prev('.event_btn').offset().top;
		var breakpoint = '(max-width: 670px)';
		if (window.matchMedia(breakpoint).matches) {
			$(viewport).animate({scrollTop : targetBtn - 60}, speed, 'easeInSine');
		}else{
			$(viewport).animate({scrollTop : targetBtn}, speed, 'easeInSine');
		}
	}
};



/* =====================================================================
	accordion
======================================================================== */
var $trigger= $('.event').find('.event_btn'),
	accordContent = '.event_toggle';

$trigger.on('click', function() {
	var $this = $(this);

	if($this.hasClass('active')) {
		$this.removeClass('active')
			 .next(accordContent).stop().slideUp(500, 'easeOutQuint');
	} else {
		$this.addClass('active')
			 .next(accordContent).stop().slideDown(500, 'easeOutQuint', targetAction);
	}

	function targetAction() {
		var targetBtn = $this.offset().top;
		var breakpoint = '(max-width: 670px)';
		if (window.matchMedia(breakpoint).matches) {
			$(viewport).animate({scrollTop : targetBtn - 60}, speed, 'easeInSine');
		}else{
			$(viewport).animate({scrollTop : targetBtn}, speed, 'easeInSine');
		}
	}
	
});



/* =====================================================================
	modal
======================================================================== */
$('.modal_contents').prepend('<div class="modal_bg"></div>');
$('.modal_open').on('click',function(){
	$('.modal_contents').fadeIn();
	return false;
});
$('.modal_bg,.modal_close').on('click',function(){
	$('.modal_contents').fadeOut();
});


/* =====================================================================
	sponsor bx
======================================================================== */
$('.sponsor_wrap').clone().addClass('ticker').insertBefore('.sponsor_wrap');
$('.sponsor_wrap.ticker').bxSlider({
	speed: 30000,
	ticker: true
});



/* =====================================================================
	sponsor swiper
======================================================================== */
// const swiper = new Swiper(".swiper", {
// 	loop: true,
// 	slidesPerView: "auto",
// 	speed: 1000,
// 	allowTouchMove: false, 
// 	autoplay: {
// 		delay: 0,
// 		disableOnInteraction: false,
// 	}
// });


/* =====================================================================
	sponsor slick
======================================================================== */

$('.slick').slick({
	autoplay:true,
	autoplaySpeed:0,
	arrows:false,
	draggable:false,
	speed:3000,
	variableWidth: true,
	pauseOnFocus: false,
    pauseOnHover: false,
})
.on('afterChange', function(event, slick, currentSlide, nextSlide) {
	switch (currentSlide){
	case 0:
	// 1枚目のスライド
	$(this).slick('slickSetOption', 'speed', 3000);
	break;
	default:
	// その他のスライド
	$(this).slick('slickSetOption', 'speed', 1500);
	break;
	}
  });

/* =====================================================================
	match height
======================================================================== */
$('.mh').matchHeight();


/* =====================================================================
	vertical middle
======================================================================== */
$('.vm').verticalMiddle();


/* =====================================================================
	change svg style
======================================================================== */
deSVG('.desvg', true);


/* =====================================================================
	scroll-hint
======================================================================== */
window.addEventListener('DOMContentLoaded', function(){
  new ScrollHint('.js-scrollable',{
	i18n: {
		scrollable: 'スクロールできます'
	}
  });
});
